exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-project-request-tsx": () => import("./../../../src/pages/project-request.tsx" /* webpackChunkName: "component---src-pages-project-request-tsx" */),
  "component---src-pages-services-ai-integrations-tsx": () => import("./../../../src/pages/services/ai-integrations.tsx" /* webpackChunkName: "component---src-pages-services-ai-integrations-tsx" */),
  "component---src-pages-services-apps-tsx": () => import("./../../../src/pages/services/apps.tsx" /* webpackChunkName: "component---src-pages-services-apps-tsx" */),
  "component---src-pages-services-our-solutions-tsx": () => import("./../../../src/pages/services/our-solutions.tsx" /* webpackChunkName: "component---src-pages-services-our-solutions-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-services-web-mobile-tsx": () => import("./../../../src/pages/services/web-mobile.tsx" /* webpackChunkName: "component---src-pages-services-web-mobile-tsx" */),
  "component---src-pages-services-website-tsx": () => import("./../../../src/pages/services/website.tsx" /* webpackChunkName: "component---src-pages-services-website-tsx" */),
  "component---src-pages-thank-you-for-request-tsx": () => import("./../../../src/pages/thank-you-for-request.tsx" /* webpackChunkName: "component---src-pages-thank-you-for-request-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

